/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/1.png"),
    altText: "India",
    caption: "Alpharetta Fire & EMS",
  },
  {
    src: require("assets/img/2.png"),
    altText: "India",
    caption: "Music for Dementia",
  },
  {
    src: require("assets/img/4.png"),
    altText: "India",
    caption: "Rural Camp",
  },
  {
    src: require("assets/img/5.png"),
    altText: "India",
    caption: "Rural Camp",
  },
  {
    src: require("assets/img/6.png"),
    altText: "India",
    caption: "Rural Camp",
  },
  {
    src: require("assets/img/7.png"),
    altText: "India",
    caption: "Rural Camp",
  },
  {
    src: require("assets/img/8.png"),
    altText: "India",
    caption: "Rural Camp",
  },
  {
    src: require("assets/img/10.png"),
    altText: "India",
    caption: "Rural Camp",
  },
];

function SectionCarousel() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section pt-o" id="carousel">
        <Container>
          <Row>
          <div className="title" >
              <h3>About Splash of Care</h3>
              <p>
              Splash of Care was founded by two teenagers. Splash of Care is a 
              non-profit organization that improves healthcare accessibility through rural, primary 
              care medical camps, health education, alternate treatments for untreatable diseases, 
              and legislative changes to reduce the scarcity of medical professionals for the 
              underserved. Fearless of the long term journey, focused on their dream, the organization
              supports dozens of rural camps globally in partnership with local 
              medical and public sector professionals, through private donations.
              </p>
            </div>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionCarousel;
