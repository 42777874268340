/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";
import NavItem from "reactstrap/lib/NavItem";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Col from "reactstrap/lib/Col";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
          <a class="navbar-brand" href="mailto:splashofcare@gmail.com">Email Us</a>
          <button class="navbar-toggler" type="button" 
                  data-toggle="collapse"   
                  data-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
          </button>

              <a 
                data-placement="bottom"
                href="https://twitter.com/SplashOfCare"
                target="_blank"
                title="Follow us on Twitter" >  
                <i className="fa fa-twitter fa-lg" />
                <p className="d-lg-none">Twitter</p>
            </a>   
            <button class="navbar-toggler" type="button" 
                  data-toggle="collapse"   
                  data-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
          </button>
        
            <a 
                data-placement="bottom"
                href="https://www.google.com/search?q=Splash+of+Care+Inc+Alpharetta&stick=H4sIAAAAAAAAAONgecSYyy3w8sc9YamUSWtOXmOM4-IKzsgvd80rySypFArgYoOyVLgEpXj10_UNDbOKMgqMK7KyNBik-LlQhaQUlLh4LywNEhF1UDl3VkuIU919i9f3-V87BD86xEh-1Dk3j2cRq2xwQU5icYZCfpqCc2JRqoJnXrKCY05BBpBdUpIIAEDDrqCSAAAA&sa=X&ved=2ahUKEwimpYXegp7rAhUJ2FkKHUNIDgUQ6RMwC3oECAsQBA&biw=1310&bih=586"
                target="_blank"
                title="find us on google" >
    
                <i className="fa fa-google fa-lg" />
                <p className="d-lg-none">google</p>
             </a>
             <button class="navbar-toggler" type="button" 
                  data-toggle="collapse"   
                  data-target="#navbarSupportedContent" 
                  aria-controls="navbarSupportedContent" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
          </button>
            
            <a
                data-placement="bottom"
                href="https://www.linkedin.com/company/splash-of-care"
                target="_blank"
                title="Follow us on LinkedIn">
                <i className="fa fa-linkedin-square fa-lg" />
                <p className="d-lg-none">LinkedIn</p>
             </a>  
          
          </nav>
          </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
