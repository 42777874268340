/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionTeam() {
  return (
    <>
      <Container className="tim-container">
        <br />
        <div id="team">
          <Container>
            <div className="title">
              <h3>Our Team</h3>
            </div>
            <Row>
              <Col className="mr-auto" md="3" sm="4">
                <h4 className="images-title">Divya Vikram</h4>
                <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/13.png")}
                />
                <p className="text-center">Co-Founder</p>
              </Col>
              <Col className="mr-auto" md="3" sm="4">
                <h4 className="images-title">Sandya Vikram</h4>
                <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/12.png")}
                />
                <p className="text-center">Co-Founder</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default SectionTeam;
