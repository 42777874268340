import React from "react";

// reactstrap components
import { Container, Row, Col , Button} from "reactstrap";

// core components

function SectionProject() {
  return (
    <>
      <Container className="tim-container">
        <br />
        <div id="projects">
          <Container>
            <div className="title">
              <h3>Projects</h3>
            </div>
            <Row>
              <Col className="mr-auto" md="3" sm="4">
                <Button
                className="btn-primary btn-lg btn-round"
                color="default"
                href="/index"
                target="_blank"
              >
                Medical Camps
              </Button>
              </Col>
              <Col className="mr-auto" md="3" sm="4">
              <Button
                className="btn-primary btn-lg btn-round"
                color="default"
                href="https://www.tinyurl.com/dementia-care"
                target="_blank"
              >
                Dementia Care
              </Button>
      
             </Col>
             <Col className="mr-auto" md="3" sm="4">
              <Button
                className="btn-primary btn-lg btn-round"
                color="default"
                href="/index"
                target="_blank"
              >
                Recognition
              </Button>
      
             </Col>
            </Row>
            <Row>
               <Col>
                <p>
                               
                </p>
            
               </Col>
               <Col>
               <p>
                              
              </p>
                         
               </Col>
               <Col>
               <p>
                              
              </p>
               </Col>
            </Row>
            <Row>
              <Col>
              <p>
              It is generally perceived that in well-developed metros, there is plenty of access to 
              medical care.  Yes, there are hundreds of doctors and dozens of hospitals. Still,
              many do not seek medical attention as it is prohibitively expensive. However, the 
              need is even more acute in rural, arid parts of developing countries like India, 
              Sri Lanka, and nearby and continents like Africa. Our goal is to partner with small 
              local medical establishments to quench the paucity 
              of unfairness related to making health less of a fear and more of a right.
              </p>
              </Col>

              <Col>
              <p>
              Although deaths by other diseases such as heart diseases have decreased, deaths from 
              Dementia have increased by 123% between 2000 and 2015. There are nearly 10 million new 
              cases of Dementia every year, implying that there is one new case of Dementia every 3.2 seconds. The cure for
              Dementia is as elusive as ever for the past century. However, there are many
              alternative treatments, including music, activities, pictures, and human 
              compassion. 
              For additional information, visit <a href= "https://www.tinyurl.com/dementia-care"> Dementia Care </a>
              </p>
              </Col>
              <Col>
              <p>
              Recognition by the local community for Splash of Care addressing healthcare challenges, 
              though very humbling, is something we are proud of. We believe that these awards 
              are a recognition of the need, more than the effort, as the journey ahead is a long one. 
              We continue making small steps collaboratively through new partnerships 
              and supporting other non-profits 
              to bring more smiles on faces! 
              </p>
              </Col>
            </Row>
            <Row>
              <Col>
              <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/9.png")}
                />

              </Col>
              <Col>
              <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/3.png")}
                />

              </Col>
              <Col>
              <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/11.png")}
                />

              </Col>
            </Row>

          </Container>
        </div>
      </Container>
    </>
  );
}

export default SectionProject;